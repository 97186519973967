import Nav from "./nav";
import Section1 from "./section-1";
import Section2 from "./section-2";
import Section3 from "./section-3";
import Section4 from "./section-4";

export default function App() {
  return (
    <>
      <Nav />
      <Section1 />
      <Section2 />
      <Section3 />
      <Section4 />
      <div
        className='w-full py-8 flex justify-center items-center italic'
        style={{ fontFamily: "Inter" }}
      >
        <a
          href='https://integrators.lv?utm_source=limpo.lv'
          target='_blank'
          rel='noreferrer'
          className='font-bold hover:text-blue-600 transition-colors'
        >
          Izstrādāja INTEGRATORS.lv
        </a>
      </div>
    </>
  );
}
