import axios from "axios";
import React from "react";
import { useCookies } from "react-cookie";
import { API_BASE_URL } from "./config";

const checkStatus = async () => {
  try {
    await axios.get(`${API_BASE_URL}/admin/status`, {
      withCredentials: true,
    });
  } catch (err: any) {
    console.error("ERROR: admin.status.get", err);
  }
};

export default function Admin() {
  const [cookies, , removeCookie] = useCookies(["api_key"]);

  React.useEffect(() => {
    checkStatus();
  }, []);

  if (!cookies.api_key) {
    return <Login />;
  }

  return (
    <div className="p-4 max-w-[768px] mx-auto flex flex-col gap-4">
      <nav className="flex flex-row justify-between items-center">
        <p className="text-2xl text-red font-LemonMilkProBold">LIMPO - 2024</p>
        <button className="bg-red text-yel py-2 px-4 disabled:opacity-50">
          <span
            onClick={() => removeCookie("api_key")}
            className="font-LemonMilkProBold"
          >
            Iziet
          </span>
        </button>
      </nav>
      <Winners />
    </div>
  );
}

const Winners = () => {
  const [data, setData] = React.useState({
    airpods: { title: "airpods max austiņas", winners: [] },
    iphone: { title: "iphone 15 128 gb", winners: [] },
    nintendo: { title: "nintendo switch spēļu konsole", winners: [] },
    limpo: { title: "kaste ar limonādi", winners: [] },
  });

  const loadData = async () => {
    try {
      const { data: res } = await axios.get(
        `${API_BASE_URL}/limpo/winner/list`
      );

      setData({
        airpods: { title: "airpods max austiņas", winners: res.data.airpods },
        iphone: { title: "iphone 15 128 gb", winners: res.data.iphone },
        nintendo: {
          title: "nintendo switch spēļu konsole",
          winners: res.data.nintendo,
        },
        limpo: { title: "kaste ar limonādi", winners: res.data.limpo },
      });
    } catch (err: any) {
      console.error("ERROR: loadData:", err);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  const deleteWinner = async (id: string) => {
    try {
      await axios.delete(`${API_BASE_URL}/limpo/winner/${id}`, {
        withCredentials: true,
      });
    } catch (err: any) {
      console.error("ERROR: deleteWinner:", err);
      alert("Kaut kas nogāja greizi ielādējot datus, mēģini vēlreiz!");
    }
    loadData();
  };

  return (
    <div className="flex flex-col gap-12 py-8">
      {Object.entries(data).map(([key, item]) => (
        <div className="flex flex-col gap-4 ">
          <p className="uppercase font-LemonMilkProMedium text-red text-2xl pb-2">
            {item.title}
          </p>

          {item.winners.length > 0 && (
            <div>
              {item.winners.map((winner: any) => (
                <div className="flex flex-row gap-2 items-center">
                  <p className="capitalize font-LemonMilkProMedium">
                    {winner.name} {`[ID: ${winner.id}]`}
                  </p>
                  <span
                    onClick={() => deleteWinner(winner.id)}
                    className="text-sm cursor-pointer bg-red text-yel font-bold rounded-full px-2 text-center"
                  >
                    dzēst
                  </span>
                </div>
              ))}
            </div>
          )}

          <AddWinner prize={key} refetchWinners={loadData} />
        </div>
      ))}
    </div>
  );
};

const AddWinner = ({
  prize,
  refetchWinners,
}: {
  prize: string;
  refetchWinners: () => void;
}) => {
  const [name, setName] = React.useState<string>("");
  const [loading, setLoading] = React.useState<boolean>(false);

  const add = async () => {
    setLoading(true);
    try {
      await axios.post(
        `${API_BASE_URL}/limpo/winner`,
        { name, prize },
        { withCredentials: true }
      );
      setName("");
    } catch (err: any) {
      console.error("ERROR: addWinner:", err);
      alert("Kaut kas nogāja greizi pievienojot ierakstu, mēģini vēlreiz!");
    }
    refetchWinners();
    setLoading(false);
  };

  return (
    <div className="flex flex-row gap-2">
      <input
        placeholder="Vārds, kurš rādīsies lapā"
        value={name}
        onChange={(e) => setName(e.target.value)}
        className="w-[300px] px-4"
      />
      <button
        onClick={add}
        disabled={loading || name.length < 2}
        className="bg-red text-yel py-2 px-4 disabled:opacity-50"
      >
        <span>Pievienot</span>
      </button>
    </div>
  );
};

const Login = () => {
  const [, setCookie] = useCookies(["api_key"]);

  const [password, setPassword] = React.useState<string>("");

  const onSubmit = async () => {
    try {
      const { data } = await axios.post(
        `${API_BASE_URL}/admin/login`,
        {
          password,
        },
        { withCredentials: true }
      );

      setCookie("api_key", data.data.api_key);
    } catch (err: any) {
      console.error("ERROR: Login:", err);
      alert("Kaut kas nogāja greizi autorizējoties, mēģini vēlreiz!");
    }
  };

  return (
    <div className="flex h-screen justify-center items-center">
      <div>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Parole"
          className="py-2 px-4"
        />
        <button
          onClick={onSubmit}
          disabled={password.length < 4}
          className="bg-red text-yel py-2 px-4 disabled:opacity-50"
        >
          <span>Ienākt</span>
        </button>
      </div>
    </div>
  );
};
