import cuka from "./assets/images/cuka.png";
import sauklis2 from "./assets/images/sauklis-2.png";
import sauklis from "./assets/images/sauklis.png";
import rules from "./assets/pdf/rules.pdf";

const navItems = [
  { title: "reģistrējies", path: "#register" },
  { title: "balvas", path: "#prizes" },
  { title: "laimētāji", path: "#winners" },
  { title: "noteikumi", path: rules },
];

export default function Nav() {
  return (
    <nav className="bg-red">
      <div className="py-8 pb-0 px-8 max-w-[768px] mx-auto flex flex-row gap-2 items-center justify-between">
        <img
          src={sauklis}
          alt="Sauklis"
          className="w-[100px] md:w-[150px] hidden md:block"
        />
        {navItems.map(({ title, path }) => (
          <a
            href={path}
            className="uppercase text-bold text-yel text-2xl font-PFDinTextCompProRegular"
          >
            {title}
          </a>
        ))}
      </div>

      <div className="max-w-[768px] px-8 mx-auto">
        <div className="flex flex-row items-center py-8 md:py-0">
          <img
            src={sauklis2}
            alt=""
            className="h-auto md:w-[500px] w-[300px]"
          />
          <img
            src={cuka}
            alt=""
            className="h-[200px] hidden md:block md:h-[250px] relative md:-left-[50px] -bottom-[18px] md:-bottom-[22px]"
          />
        </div>
      </div>

      {/* <div className=" relative block w-[100px] mx-auto left-[200px] h-[100px] bg-blue-600"></div> */}
    </nav>
  );
}
