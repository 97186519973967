import { zodResolver } from "@hookform/resolvers/zod";
import moment from "moment";
import { FieldError, useForm, UseFormRegister } from "react-hook-form";
import * as z from "zod";
import pudeles2 from "./assets/images/pudeles-2.png";
import pudeles3 from "./assets/images/pudeles-3.png";
import rules from "./assets/pdf/rules.pdf";
import { API_BASE_URL } from "./config";
import axios from "axios";
import React from "react";

export const registrationSchema = z.object({
  first_name: z
    .string({ required_error: "* Vārds ir nepieciešams" })
    .min(2, { message: "* Nepieciešams garāks par 2 simboliem" })
    .max(32),
  last_name: z
    .string({ required_error: "* Uzvārds ir nepieciešams" })
    .min(2, { message: "* Nepieciešams garāks par 2 simboliem" })
    .max(32),
  // email: z
  //   // .string({ required_error: "* E-pasts ir nepieciešams" })
  //   .email({ message: "* Ievadiet pareizu e-pastu" })
  //   .optional(),
  email: z.union([
    z.literal(""),
    z.string().email({ message: "* Ievadiet pareizu e-pastu" }),
  ]),
  phone_number: z
    .string({ required_error: "* Tālrunis ir nepieciešams" })
    .length(8, { message: "* Tālrunī jābūt 8 cipariem" }),
  age: z.union([z.number().int().positive().min(1), z.nan()]).optional(),
  // age: z.number({
  //   required_error: "* Vecums ir nepieciešams",
  //   invalid_type_error: "* Nederīgs",
  // }),
  // .min(18, { message: "* Vecumam jābūt virs 18 gadiem" }),
  rules: z.literal<boolean>(true, {
    errorMap: () => ({ message: "* Nepieciešams piekrist noteikumiem" }),
  }),
  regulations: z.literal<boolean>(true, {
    errorMap: () => ({ message: "* Nepieciešams apstiprināt datu apstrādi" }),
  }),
  receipt_number: z
    .string({ required_error: "* Čeka numurs ir nepieciešams" })
    .min(4, { message: "* Čeka numurs ir nepieciešams" }),
});

type FormData = z.infer<typeof registrationSchema>;

export default function Section2() {
  const [IP, setIP] = React.useState<string>("127.0.0.1");

  const getIP = async () => {
    try {
      const res = await axios.get("https://geolocation-db.com/json/");
      setIP(res.data.IPv4);
    } catch (err: any) {
      console.error("ERROR: getIP", err);
    }
  };

  React.useEffect(() => {
    getIP();
  }, []);

  const form = useForm<FormData>({
    resolver: zodResolver(registrationSchema),
    defaultValues: {},
    mode: "onChange",
  });

  const resetForm = () => {
    form.reset({
      first_name: "",
      last_name: "",
      email: "",
      phone_number: "",
      age: "" as any,
      rules: false,
      receipt_number: "",
      regulations: false,
    });
  };

  const formErrors = form.formState.errors;

  const handleLogin = async (
    data: any,
    e?: React.BaseSyntheticEvent // This one specifically.
  ) => {
    e?.preventDefault(); // Stop page refresh
    console.log("debug data ->", data);
    try {
      const filteredData: any = {};

      for (const key in data) {
        if (!data[key]) {
          continue;
        }

        filteredData[key] = data[key];
      }

      await axios.post(`${API_BASE_URL}/limpo/register`, { ...filteredData, ip: IP });

      resetForm();

      alert("Paldies, reģistrācija veiksmīga!");
    } catch (err: any) {
      console.error("ERROR: onSubmit:", err);

      alert(
        `Kaut kas nogāja greizi, mēģini vēlreiz. ${err?.response?.data?.data?.message}`
      );
    }
  };

  const start = moment("2024-05-01", "YYYY-MM-DD");
  const end = moment("2024-07-01", "YYYY-MM-DD");

  const registrationOpen = moment() >= start && moment() <= end;

  return (
    <div>
      <div className='px-8 py-4 bg-red'>
        <p
          id='register'
          className='uppercase max-w-[768px] mx-auto font-LemonMilkProBold text-yel text-4xl'
        >
          reģistrēties
        </p>
      </div>

      <div className='relative max-w-[768px] px-8 mx-auto py-8'>
        {/* <Form {...form}> */}
        <form
          onSubmit={form.handleSubmit(handleLogin)}
          className=' flex flex-col gap-6'
        >
          <div className='grid md:grid-cols-2 grid-cols-1 gap-2'>
            <FormField
              name='first_name'
              type='text'
              placeholder='Vārds'
              register={form.register}
              error={formErrors.first_name}
            />

            <FormField
              name='last_name'
              type='text'
              placeholder='Uzvārds'
              register={form.register}
              error={formErrors.last_name}
            />

            <div className='grid grid-cols-4 gap-2'>
              <FormField
                name='age'
                type='number'
                placeholder='Vecums'
                register={form.register}
                error={formErrors.age}
                valueAsNumber={true}
                className='col-span-1'
              />
              <FormField
                name='phone_number'
                type='tel'
                placeholder='Tālrunis'
                register={form.register}
                error={formErrors.phone_number}
                className='col-span-3'
              />
            </div>

            <FormField
              name='email'
              type='text'
              placeholder='E-pasts'
              register={form.register}
              error={formErrors.email}
            />

            <FormField
              name='receipt_number'
              type='text'
              placeholder='Čeka numurs'
              register={form.register}
              error={formErrors.receipt_number}
            />
          </div>

          <div className='grid grid-cols-1 gap-4 md:gap-0 md:grid-cols-2'>
            <div className='col-span-1 flex flex-col gap-2'>
              <div className='flex flex-col'>
                <div className='flex flex-row gap-2 items-center'>
                  <input
                    type='checkbox'
                    {...form.register("rules")}
                    className='w-6 h-6'
                  />
                  <label
                    htmlFor='rules'
                    className='font-PFDinTextCondProXBlack'
                  >
                    Es piekrītu kampaņas{" "}
                    <a href={rules} className='underline text-red'>
                      noteikumiem
                    </a>
                  </label>
                </div>
                {formErrors.rules && (
                  <p className='text-red text-sm'>{formErrors.rules.message}</p>
                )}
              </div>

              <div className='flex flex-col'>
                <div className='flex flex-row gap-2 items-center'>
                  <input
                    type='checkbox'
                    {...form.register("regulations")}
                    className='w-6 h-6'
                  />
                  <label
                    htmlFor='regulations'
                    className='font-PFDinTextCondProXBlack'
                  >
                    Piekrītu datu apstrādes noteikumiem*
                  </label>
                </div>
                {formErrors.regulations && (
                  <p className='text-red text-sm'>
                    {formErrors.regulations.message}
                  </p>
                )}
              </div>
            </div>

            <div className='col-span-1 flex justify-start md:justify-end items-center'>
              <button
                disabled={!registrationOpen}
                className='bg-red px-8 py-2 h-[56px] disabled:opacity-50'
              >
                <span className='uppercase text-yel text-2xl font-PFDinTextCompProRegular'>
                  {registrationOpen
                    ? "reģistrēties"
                    : "reģistrācija no 1.maija"}
                </span>
              </button>
            </div>
          </div>

          <p className='text-sm'>
            *Reģistrējot dalību loterijai, Tu piekrīti, ka SIA “Visas Loterijas”
            veic šādu personas datu apstrādi. Vārds, Uzvārds nepieciešams lai
            nodrošinātu Jūsu identifikāciju laimesta gadījumā un pārliecinātos
            par iesniegto datu pareizību. Tālruņa numurs nepieciešams, lai
            varētu ar Tevi sazināties gadījumā, ja būsi laimējis. Dokumenta/čeka
            numurs nepieciešams, lai indentificēta pirkuma veikšanu un
            atbilstību loterijas noteikumiem. Datu subjektiem, piedaloties šajā
            loterijā, ir visas GDPR noteiktās tiesības. Vairāk info{" "}
            <a
              style={{ textDecoration: "underline" }}
              href='https://www.cesualus.lv/personas-datu-aizsardzibas-politika/'
              target='_blank'
              rel='noopener noreferrer'
            >
              šeit
            </a>
            .
          </p>
        </form>
        <div className='absolute -right-[350px] flex flex-col gap-8 -top-[100px]'>
          <img src={pudeles2} alt='' className='hidden md:block' />
          <img src={pudeles3} alt='' className='hidden md:block' />
        </div>
        {/* </Form> */}
      </div>
    </div>
  );
}

export type ValidFieldNames =
  | "first_name"
  | "last_name"
  | "email"
  | "age"
  | "phone_number"
  | "receipt_number";

type FormFieldProps = {
  type: string;
  placeholder: string;
  name: ValidFieldNames;
  register: UseFormRegister<FormData>;
  error: FieldError | undefined;
  valueAsNumber?: boolean;
  className?: string;
};

const FormField: React.FC<FormFieldProps> = ({
  type,
  placeholder,
  name,
  register,
  error,
  valueAsNumber,
  className = "",
}) => (
  // <>
  // label
  //   <input
  //     type={type}
  //     placeholder={placeholder}
  //     {...register(name, { valueAsNumber })}
  //   />
  //   {error && <span className="error-message">{error.message}</span>}
  // </>

  <div className={`flex flex-col ${className}`}>
    <label htmlFor={name} className='font-ProximaNovaBold'>
      {placeholder}
    </label>
    <input
      type={type}
      {...register(name, { valueAsNumber })}
      className='outline-none py-2 px-2 font-LemonMilkProRegular'
    />
    {error ? <p className='text-red text-sm'>{error.message}</p> : null}
  </div>
);
