import axios from "axios";
import React from "react";
import cuka from "./assets/images/cuka.png";
import pudeles from "./assets/images/pudeles.png";
import sauklis3 from "./assets/images/sauklis-3.png";
import { API_BASE_URL } from "./config";

export default function Section4() {
  const [data, setData] = React.useState({
    airpods: { title: "AirPods Max austiņas", winners: [] },
    iphone: { title: "iPhone 15 128 GB", winners: [] },
    nintendo: { title: "Nintendo Switch spēļu konsoli", winners: [] },
    limpo: { title: "kasti ar limonādi ", winners: [] },
  });

  const loadData = async () => {
    try {
      const { data: res } = await axios.get(
        `${API_BASE_URL}/limpo/winner/list`
      );

      setData({
        airpods: { title: "AirPods Max austiņas", winners: res.data.airpods },
        iphone: { title: "iPhone 15 128 GB", winners: res.data.iphone },
        nintendo: {
          title: "Nintendo Switch spēļu konsoli",
          winners: res.data.nintendo,
        },
        limpo: { title: "kasti ar limonādi ", winners: res.data.limpo },
      });
    } catch (err: any) {
      console.error("ERROR: loadData:", err);
    }
  };

  React.useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div className="px-8 py-4 bg-red">
        <p
          id="winners"
          className="uppercase max-w-[768px] mx-auto font-LemonMilkProBold text-yel text-4xl"
        >
          uzvarētāji
        </p>
      </div>
      <div></div>

      <div className="relative max-w-[768px] px-8 mx-auto flex flex-col gap-12 py-8">
        {Object.values(data).map((item) => (
          <div className="text-center">
            <p className="uppercase font-LemonMilkProMedium text-red text-2xl pb-2">
              {item.title} laimējuši:
            </p>
            {!item.winners.length ? (
              <p className="font-LemonMilkProMedium">
                Laimētāji vēl nav izvēlēti
              </p>
            ) : (
              <div>
                {item.winners.map((winner: any) => (
                  <p className="capitalize font-LemonMilkProMedium">
                    {winner.name}
                  </p>
                ))}
              </div>
            )}
          </div>
        ))}
        <div className="absolute -right-[350px] flex flex-col gap-8 -top-[100px]">
          <img src={cuka} alt="" className="hidden md:block" />
          <img src={pudeles} alt="" className="hidden md:block" />
          {/* <img src={pudeles2} alt="" className="hidden md:block" /> */}
          {/* <img src={pudeles3} alt="" className="hidden md:block" /> */}
          {/* <img src={pudeles} alt="" className="hidden md:block" /> */}
        </div>
      </div>

      <div>
        <img
          src={sauklis3}
          alt="Dzīve kā limonāde"
          className="mx-auto py-8 max-w-[300px]"
        />
      </div>
    </div>
  );
}
