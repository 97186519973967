import balvas from "./assets/images/balvas.png";
import pudeles from "./assets/images/pudeles.png";

export default function Section1() {
  return (
    <nav className="bg-yel">
      <div className="max-w-[768px] px-8 mx-auto py-8 flex flex-row">
        <img src={balvas} alt="" />
        <img src={pudeles} alt="" className="hidden md:block" />
      </div>
    </nav>
  );
}
