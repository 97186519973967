import balvas2 from "./assets/images/balvas-2.png";

export default function Section3() {
  return (
    <div>
      <div className="px-8 py-4 bg-red">
        <p id="prizes" className="uppercase max-w-[768px] mx-auto font-LemonMilkProBold text-yel text-4xl">
          balvas
        </p>
      </div>

      <div className="max-w-[768px] px-8 mx-auto py-8">
        <img src={balvas2} alt="" />
      </div>
    </div>
  );
}
